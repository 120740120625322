import React from 'react';
import CardMenu from './CardMenu';

const SectionMenu = ({ id, meals, title, announcement, bg }) => {
  return (
    <div id={id} key={id} className='w-full bg-neutral-800 dark:bg-neutral-900'>
      <div id='breakfast' className='w-full bg-cover bg-center' style={{ backgroundImage: `url(${bg})`, height: 'auto' }}>
        <div className='w-full bg-neutral-800/80 dark:bg-neutral-800/80'>
          <div className='ctn-spacing container mx-auto'>
            <div className='w-full text-center py-2'>
              <h1 className='text-5xl text-amber-500 font-medium'>
                {title}
              </h1>
            </div>
            {!announcement ? null : (
              <div className='w-full text-center py-2 mx-auto'>
                <h3 className='text-2xl text-gray-200 font-normal mx-auto px-2'> {announcement}</h3>
              </div>
            )}
            <div className='ctn-spacing grid sm:md:grid-cols-2 grid-cols-1 sm:md:mx-0 mx-5 gap-4 text-center justify-items-center'>
              {!meals ? null : meals.map((meal, index) => (
                <CardMenu key={index} name={meal.name} cost={meal.cost} description={meal.description} subdescriptions={meal.subdescriptions} />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionMenu;
