import React, { useState, useEffect } from 'react'
import { Link } from 'react-scroll'
import { getStrapiHero }  from './data/getStrapiHero'

const Navbar = ({theme, changeTheme}) => {
  const [strapiContent, setStrapiContent] = useState([])
  const collectionData = 'heroes?[populate]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiHero(collectionData)
      setStrapiContent(data)
    }
    fetchData()
  }, [])

  const handleTheme = () => {
    changeTheme()
  }

  return (
    <>
      <div className='w-full mx-auto mt-0 bg-stone-800/90 dark:bg-stone-900/90 text-center shadow-md'>
        <h1 className='sm:md:text-5xl text-3xl font-semibold text-zinc-200 py-2 my-4 tracking-widest'> La Cocina de Luz</h1>
      </div>
      <div className='w-full mx-auto mt-0 bg-stone-800/90 dark:bg-stone-900/90 text-center shadow-zinc-900 shadow-md sticky top-0 z-50'>
        <div className='flex sm:md:items-center sm:md:flex-nowrap flex-wrap  py-2 px-10'>
          {strapiContent?.map(({ sections_on_page }, index) => (
            <React.Fragment key={index}>
              {sections_on_page.map((item, innerIndex) => (
                <Link
                  to={item.section}
                  key={item.id || innerIndex}
                  spy={true}
                  smooth={true}
                  className='active:text-amber-500 capitalize transition delay-150 duration-300 ease-in-out sm:md:text-lg text-sm mx-auto text-zinc-300 font-medium sm:md:py-3 py-1 sm:md:px-2 px-1 hover:text-amber-500 cursor-pointer'
                >
                  {item.section}
                </Link>
              ))}
            </React.Fragment>
          ))}
          <button type='button' onClick={() => handleTheme()} className='text-amber-500 dark:text-amber-400 hover:bg-amber-100 dark:hover:bg-amber-700 focus:outline-none focus:ring-4 focus:ring-amber-200 dark:focus:ring-amber-700 rounded-lg text-sm p-2.5'>
            {theme === true && (<svg className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z'> </path></svg>)}
            {theme === false && (<svg className='w-5 h-5' fill='currentColor' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'><path d='M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z' fillRule='evenodd' clipRule='evenodd'> </path></svg>)}
          </button>
        </div>
      </div>
    </>
  )
}

export default Navbar
