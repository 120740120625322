import React, { useState, useEffect } from 'react'
import { getStrapiHero }  from './data/getStrapiHero'
import { Carousel } from 'react-carousel-minimal'

const Home = () => {
  const [strapiContent, setStrapiContent] = useState([])
  const collectionData = 'heroes?populate[hero_carousels][populate][image]=*'

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiHero(collectionData)
      setStrapiContent(data)
    }
    fetchData()
  }, [])

  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  }
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  }

  return (
    <div id='Welcome' className='w-full mx-auto bg-stone-800/50 dark:bg-stone-900 pb-20'>
      { strapiContent?.map(({id, hero_content, button_text, hero_carousels}) => (
        <div className='container mx-auto' key={id} >
          <div className='grid justify-items-center text-center mt-4 md:mt-6'>
            <p className='text-neutral-200 font-medium sm:md:text-2xl w-[70%] lg:w-[60%] text-sm sm:md:m-5 mx-5 my-4 sm:md:tracking-widest tracking-tight'>
              {hero_content}
            </p>
            <div className='flex items-center'>
              <a className='bg-amber-100 text-amber-800 text-sm font-medium mr-2 px-3.5 py-2.5 rounded dark:bg-amber-200 dark:text-amber-900' href="tel:+19707289355"> {button_text} </a>
            </div>
          </div>
          <div className='container'>
            <div style={{ textAlign: "center" }}>
              <div style={{
                padding: "0 20px"
              }}>
                <Carousel
                  data={hero_carousels}
                  time={2000}
                  width="850px"
                  height="500px"
                  captionStyle={captionStyle}
                  radius="10px"
                  slideNumber={true}
                  slideNumberStyle={slideNumberStyle}
                  captionPosition="bottom"
                  automatic={true}
                  dots={true}
                  pauseIconColor="white"
                  pauseIconSize="40px"
                  slideBackgroundColor="darkgrey"
                  slideImageFit="cover"
                  thumbnails={true}
                  thumbnailWidth="100px"
                  style={{
                    textAlign: "center",
                    maxWidth: "850px",
                    maxHeight: "500px",
                    margin: "40px auto",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )
}

export default Home
