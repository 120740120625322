import React from 'react'

const CardMenu = ({ name, cost, description = '', subdescriptions = '', index }) => {
  return (
    <div key={index} className='my-3 max-w-sm w-full rounded-lg bg-transparent dark:bg-transparent ring-1 ring-neutral-900/5 shadow-md shadow-neutral-700/50 dark:shadow-neutral-800/50 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300'>
      <div className='m-2'>
        <div className='grid grid-cols-3 mx-auto my-2'>
          <h1 className='col-span-2 w-full mx-auto text-left text-gray-400 sm:md:text-xl text-sm'>{name}</h1>
          <p className='sm:md:text-xl text-sm text-gray-400'> {cost === null? "" : ("$" + cost )} </p>
        </div>
        <p className='sm:md:text-md text-xs text-gray-400 capitalize text-left mb-4'>{description}</p>
        {!subdescriptions ? null : subdescriptions?.map((subdescription, subIndex) =>
          <React.Fragment key={subIndex}>
            <p className='sm:md:text-md text-xs text-gray-400 capitalize text-left'>{subdescription.description}</p>
            <br />
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default CardMenu;
