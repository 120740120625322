import React, { useState, useEffect } from 'react';
import SectionMenu from './menu/SectionMenu';
import { getStrapiMenu } from './data/getStrapiMenu';

const Menu = () => {
  const [strapiContent, setStrapiContent] = useState([]);
  const collectionData = 'menus?populate=*';

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiMenu(collectionData);
      const sortedData = data.sort((a, b) => a.id - b.id);
      setStrapiContent(sortedData);
    };
    fetchData();
  }, []);

  return (
    <>
      {strapiContent?.map(({ id_section, section_title, section_announcement, section_background, meals_cards }, index) => (
         <div key={index}>
          <SectionMenu
            id={id_section}
            meals={meals_cards}
            title={section_title}
            announcement={section_announcement}
            bg={section_background}
          />
        </div>
      ))}
    </>
  );
};

export default Menu;
