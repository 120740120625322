import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Menu from './components/Menu';
import Footer from './components/Footer';
import Organic from './components/Organic';
import NavbarAdmin from './components/NavbarAdmin';
import "../src/sass/main.scss"
import TableDish from './components/admin/TableDish';

const App = () => {
  const [theme, setTheme] = useState(false);

  useEffect(() => {
    applyThemeClass(); // eslint-disable-next-line
  }, [theme]);

  const changeTheme = () => {
    setTheme((prevTheme) => !prevTheme);
  };

  const applyThemeClass = () => {
    switch (theme) {
      case true:
      document.documentElement.classList.add('light');
        document.documentElement.classList.remove('dark');
        break;
      case false:
        document.documentElement.classList.remove('light');
        document.documentElement.classList.add('dark');
        break;
      default:
        document.documentElement.classList.add('light');
        break;
    }
  };

  return (
    <Routes>
      <Route
        path='/'
        element={
          <div className='flex flex-col antialiased bg-stone-700 dark:bg-stone-800 min-h-full h-full'>
            <Navbar theme={theme} changeTheme={changeTheme} />
            <Home />
            <Menu />
            <Organic />
            <Footer />
          </div>
        }
      />
      <Route
        path='/admin'
        element={
          <div className='flex flex-col antialiased bg-stone-200 dark:bg-stone-800 min-h-full h-screen'>
            <NavbarAdmin />
            <div className='mx-auto container m-2'>
              <TableDish />
            </div>
          </div>
        }
      />
    </Routes>
  );
};

export default App;
