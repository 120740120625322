const PrincipalTitle = ({ title }) => {
  return (
    <div className='w-full text-center py-2'>
      <h1 className='text-5xl text-amber-500 font-medium'>
        { title }
      </h1>
    </div>
  )
}

const SecondTitle = ({ title }) => {
  return (
    <div className='w-full text-center mx-auto'>
      <h3 className='text-xl text-gray-200 font-normal leading-10'> { title }</h3>
    </div>
  )
}

const Anoucenment = ({ text }) => {
  return (
    <div className='w-full text-center py-2 mx-auto'>
      <h3 className='text-2xl text-gray-200 font-normal mx-auto px-2'> { text }</h3>
    </div>
  )
}

const OrganicText = ({ text }) => {
  return (
    <div className='w-full text-center py-2 mx-auto'>
      <h3 className='organic-content text-2xl text-gray-200 font-normal mx-auto px-2'> { text }</h3>
    </div>
  )
}

export { Anoucenment, SecondTitle, PrincipalTitle, OrganicText }
