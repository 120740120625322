import React from 'react'

const TableDish = () => {
  return (
    <div className='overflow-x-hidden relative p-2'>
      <div className='w-full items-center h-[60vh] flex justify-center'>
        <a href="https://cocinaluz-strapi.telluridedigitalworks.com/strapi/admin"
            className='bg-orange-50 border border-orange-300 text-center text-orange-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-[200px] p-2.5 dark:bg-orange-700 dark:border-orange-600 dark:placeholder-orange-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'>
              Click here ☝🏽
        </a>
      </div>
    </div>
  )
}


export default TableDish
