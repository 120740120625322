/* eslint-disable */
const token = '5e674e236b9a96bb83eba75af765a588a24b1084e116c954caae42d69196675db92befe82a56e4df2c4f42bf3cb9dcfa744274ecd94882f20bea6373c1fde56d1b5a8071376dffbb018162846434b3323dc047ebecc245be9e920fba33ffdaf1f0efe1e15c7578eea9f667d04c2176d6b5efeaee3d3cb973ccaabcb315fb6a7f'
const STRAPI_URL = 'https://cocinaluz-strapi.telluridedigitalworks.com/strapi'

export const getStrapiHero = async (collectionId) => {
  const collection = await (await fetch(`${STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })).json()


  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        hero_content: attributes.content,
        button_text: attributes.button_text,
        sections_on_page: attributes.list_of_sections.sections_on_page.map(({section, id}) => ({
          id: id,
          section: section
        })),
        hero_carousels: attributes.hero_carousels?.data.map(({ attributes, id }) => ({
          id: id,
          caption: attributes.image_text,
          image: STRAPI_URL + (attributes?.image?.data?.attributes?.url || '')
        }))
      }
    }
  )

  return values
}
/* eslint-enable */
