/* eslint-disable */
const token = '5e674e236b9a96bb83eba75af765a588a24b1084e116c954caae42d69196675db92befe82a56e4df2c4f42bf3cb9dcfa744274ecd94882f20bea6373c1fde56d1b5a8071376dffbb018162846434b3323dc047ebecc245be9e920fba33ffdaf1f0efe1e15c7578eea9f667d04c2176d6b5efeaee3d3cb973ccaabcb315fb6a7f'
const STRAPI_URL = 'https://cocinaluz-strapi.telluridedigitalworks.com/strapi'

export const getStrapiMenu = async (collectionId) => {
  const collection = await (await fetch(`${STRAPI_URL}/api/${collectionId}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })).json()

  const values = collection?.data?.map(
    ({ attributes, id }) => {
      return {
        id: id,
        section_title: attributes.title,
        section_announcement: attributes.announcement,
        section_background: STRAPI_URL + attributes.background.data.attributes.url,
        id_section: attributes.id_section,
        meals_cards: attributes.cards?.data.map(({attributes, id}) => ({
          id: id,
          name: attributes.name,
          cost: attributes.cost,
          description: attributes.description,
          subdescriptions: attributes.subdescriptions?.descriptionSplit.map(({description}) => ({
            description: description
          }))
        })),
      }
    }
  )

  return values
}
/* eslint-enable */
