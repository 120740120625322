import React, { useState, useEffect } from 'react';
import { getStrapiOrganic } from './data/getStrapiOrganic';
import { OrganicText, PrincipalTitle } from './menu/HeadersMenu';

const Organic = () => {
  const [strapiContent, setStrapiContent] = useState([]);
  const collectionData = 'organics?populate=*';

  useEffect(() => {
    const fetchData = async () => {
      const data = await getStrapiOrganic(collectionData);
      setStrapiContent(data);
    };
    fetchData();
  }, []);

  return (
    <div id='Organic' className='mt-4 ctn-organic container mx-auto'>
      {strapiContent?.map(({ title, first_description, second_description, organic_list_one, organic_list_two, id }) => (
        <div key={id}>
          <PrincipalTitle title={title} />
          <br />
          <div>
            <OrganicText text={first_description} />
            <OrganicText text={second_description} />
          </div>
          <div className='ctn-organic__items'>
            <div className='ctn-organic__items__one'>
              <ul className='item-list'>
                {organic_list_one.map((item, index) => (
                  <li className='text-2xl text-gray-200 font-normal' key={index}>
                    {item.item}
                  </li>
                ))}
              </ul>
            </div>
            <div className='ctn-organic__items__two'>
              <ul className='item-list'>
                {organic_list_two.map((item, index) => (
                  <li className='text-2xl text-gray-200 font-normal mx-auto px-2' key={index}>
                    {item.item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Organic;
