import React from 'react'

export default class NavbarAdmin extends React.Component {
  handleTheme = () => {
    const { changeTheme } = this.props
    changeTheme()
  }
  
  render () {

    return (
      <>
        <div initial={{ opacity: 0, scale: 0.8 }} animate={{ opacity: 1, scale: 1 }} transition={{ duration: 0.5 }} className='w-full mx-auto mt-0 bg-stone-800/90 dark:bg-stone-900/90 text-center shadow-md'>
          <h1 className='sm:md:text-5xl text-3xl font-semibold text-zinc-200 py-2 my-4 tracking-widest capitalize'>
             Strapi Admin 👇🏻
          </h1>
        </div>
      </>
    )
  }
}
