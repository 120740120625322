import c1 from '../files/certificate/c1.jpeg'
import c2 from '../files/certificate/c2.png'
import c3 from '../files/certificate/c3.jpeg'
import c4 from '../files/certificate/c4.png'

const Footer = () => {
  return (
    <div className='w-full bg-neutral-800 dark:bg-neutral-900/80 py-5'>
      <div className='container text-center mx-auto'>
        <div className='grid sm:grid-cols-1 md:grid-cols-2 lg:xl:grid-cols-3 sm:md:mx-0 mx-5 gap-4 text-center justify-items-center'>
          <div className='grid justify-items-center'>
            <img src={c1} alt='' className='rounded-md shadow-md h-40' />
            <img src={c2} alt='' className='rounded-md shadow-md h-40' />
          </div>
          <img src={c3} alt='' className='rounded-md shadow-md h-80' />
          <img src={c4} alt='' className='rounded-md shadow-md h-80' />
        </div>

        <p className='text-md font-medium text-gray-400 py-5'>© La Cocina De Luz - Created By <a className='bg-amber-100 text-amber-800 text-xs font-semibold mr-2 px-2.5 py-0.5 rounded dark:bg-amber-200 dark:text-amber-900' href='https://www.tellurdedigitalnews.com'> Telluride Digital News </a></p>
      </div>
    </div>
  )
}

export default Footer
